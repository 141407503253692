import "./App.css";
import "./Comuna.scss";

import { redirect, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { LocalStorageManager } from "./managers/LocalStorageManager";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

//Views
import { ComunaAPI } from "./managers/ComunaAPI";
import { UserContext } from "./Providers/UserContext";

import ClientInvoices from "./routes/ClientInvoices";
import { loader as ClientInvoicesLoader } from "./routes/ClientInvoices";
import {
	ClientAccount,
	loader as ClientAccountLoader,
} from "./routes/ClientAccount";
import {
	ContractorAccount,
	loader as ContractorAccountLoader,
} from "./routes/ContractorAccount";
import { ClientDocs, loader as ClientDocsLoader } from "./routes/ClientDocs";
import Help from "./routes/Help";

import ClientRoot from "./routes/ClientRoot";

import { ApplicationRejectionForm } from "./components/ApplicationRejectionForm";

import ContractorRoot from "./routes/ContractorRoot";
import { loader as ContractorLoader } from "./routes/ContractorRoot";
import {
	ContractorDashboard,
	loader as DashboardLoader,
} from "./routes/ContractorDasboard_v1.1";
import ContractorProposal from "./routes/ContractorProposal";
import { ContractorOnboardingForm } from "./components/ContractorOnboarding/ContractorOnboardingForm";

import ContractorInterviewConfirmation from "./routes/ContractorInterviewConfirmation";
import ContractorForgotPassword from "./routes/ContractorForgotPassword";

import { ContractorReviewChanges } from "./components/ContractorReviewChanges";
import { ContractorRoleDescription } from "./routes/ContractorRoleDescription";
import {
	ContractorContractDetail,
	loader as ContractorContractDetailLoader,
} from "./routes/ContractorContractDetail";
import { ContractorNewInvoice } from "./routes/ContractorNewInvoice";
import { ContractorInvoicesList } from "./routes/ContractorInvoicesList";
import { ContractorDetailInvoice } from "./routes/ContractorDetailInvoice";
import {
	ContractorWithdrawMethods,
	loader as ContractorWithdrawMethodsLoader,
} from "./routes/ContractorWithdrawMethods";
import { ContractorNewWithdrawMethod } from "./routes/ContractorNewWithdrawMethod";

import { Calculator } from "./routes/Calculator";

import ChangeEmail from "./routes/ChangeEmail";
import { loader as ChangeEmailLoader } from "./routes/ChangeEmail";
import EmptySite from "./components/EmptySite";

/** New views and components  */
import {
	ClientHiring,
	loader as ClientHiringLoader,
} from "./routes/ClientHiring_V1.1";
import { ClientHiringDetail } from "./routes/ClientHiringDetail_V1.1";
import { SignIn } from "./components/SignIn";
import { SignUp } from "./components/SignUp";
import {
	ClientOnboarding,
	loader as ClientOnboardingLoader,
} from "./components/ClientOnboarding";
import {
	EditRequestForm,
	Loader as EditRequestFormLoader,
} from "./routes/EditRequestForm_V1.1";
import {
	ClientMyTeam,
	Loader as ClientMyTeamLoader,
} from "./routes/ClientMyTeam";
import { ClientMyTeamDetail } from "./routes/ClientMyTeamDetail";
import {
	ClientMyTeamEdit,
	Loader as ClientMyTeamEditLoader,
} from "./routes/ClientMyTeamEdit";
import { ClientApplicationDetailView } from "./components/ClientApplicationDetailView_V1.1";
import {
	ClientProposalForm,
	loader as ClientProposalFormLoader,
} from "./routes/ClientProposalForm";
import { AskForInterview } from "./routes/AskForInterview";
import {
	ClientAccountProfile,
	loader as ClientAccountProfileLoader,
} from "./routes/ClientAccountProfile";
import {
	ClientManageUsers,
	loader as ClientManageUsersLoader,
} from "./routes/ClientManageUsers";

import { ResourceRequestPublicView } from "./routes/ResourceRequestPublicView";
import {
	ShareResourceRequest
} from './routes/ShareResourceRequest';

import MouseLoaderIndicator from "./components/MouseLoaderIndicator";

import { NewRequestForm } from "./routes/NewRequestForm";

const baseRoutes = [
    {
        path: "/request/public/:id/:key",
        element: <ResourceRequestPublicView />,
    },
];

//auth router for unknown users.
const authRouter = createBrowserRouter([
    ...baseRoutes,
	{
		path: "/",
		element: <SignIn />,
	},
	{
		path: "/signup",
		element: <SignUp />,
	},
	{
		path: "/clientonboarding",
		element: <ClientOnboarding />,
		loader: ClientOnboardingLoader,
	},
	{
		path: "signin",
		element: <ContractorOnboardingForm />,
	},
	{
		path: "interview",
		element: <ContractorInterviewConfirmation />,
	},
	{
		path: "/reset-password/:userId/:emailToken",
		element: <ContractorForgotPassword />,
	},
	{
		path: "forgot-password",
		element: <ContractorForgotPassword />,
	},
	{
		path: "calculator",
		element: <Calculator />,
	},
	{ path: "dashboard", element: <div /> },
	{ path: "*", element: <EmptySite /> },
]);

//client router for MUNA clients
const clientRouter = createBrowserRouter([
    ...baseRoutes,
	{
		path: "/",
		element: <ClientRoot />,
		children: [
			{
				path: "hiring",
				element: <ClientHiring />,
				loader: ClientHiringLoader,
				id: "hiring",
				children: [
					/* NOT IN USE{
						path:"new",
						id: "new_request",
						element: <NewRequestForm />,
					},*/
					{
						path: ":request_id",
						id: "hiring_detail",
						element: <ClientHiringDetail />,
						children: [
							{
								path: "edit",
								id: "hiring_detail_edit",
								element: <EditRequestForm />,
								loader: EditRequestFormLoader,
							},
							{
								path: "share",
								id: "hiring_detail_share",
								element: <ShareResourceRequest />
							},
							{
								path: "application/:application_id",
								id: "hiring_application_detail",
								element: <ClientApplicationDetailView />,
								children: [
									{
										path: "proposal/:proposal_id",
										id: "proposal_detail",
										element: <ClientProposalForm />,
										loader: ClientProposalFormLoader,
									},
									{
										path: "interview",
										element: <AskForInterview />,
									},
									{
										path: "reject",
										element: <ApplicationRejectionForm />,
									},
								],
							},
						],
					},
				],
			},

			{
				path: "team",
				id: "team",
				element: <ClientMyTeam />,
				loader: ClientMyTeamLoader,
				children: [
					{
						path: ":contract_id",
						id: "team_detail",
						element: <ClientMyTeamDetail />,
					},
					{
						path: ":contract_id/edit",
						id: "team_detail_edit",
						element: <ClientMyTeamEdit />,
						loader: ClientMyTeamEditLoader,
					},
				],
			},
			{
				path: "invoices",
				id: "invoices_view",
				element: <ClientInvoices />,
				loader: ClientInvoicesLoader,
			},
			{
				path: "invoices/:invoice_id",
				id: "invoices_view_detail",
				element: <ClientInvoices />,
				loader: ClientInvoicesLoader,
			},
			{
				path: "account",
				element: <ClientAccount />,
				loader: ClientAccountLoader,
				children: [
					{
						path: "email-update/confirm/:userid/:uuid",
						element: <ChangeEmail />,
						loader: ChangeEmailLoader,
					},
					{
						path: "yourprofile",
						element: <ClientAccountProfile userProfile={true} />,
						loader: ClientAccountProfileLoader,
					},
					{
						path: "companyprofile",
						element: <ClientAccountProfile userProfile={false} />,
						loader: ClientAccountProfileLoader,
					},
					{
						path: "manageusers",
						element: <ClientManageUsers />,
						loader: ClientManageUsersLoader,
					},
				],
			},
			{
				path: "docs",
				element: <ClientDocs />,
				loader: ClientDocsLoader,
			},
			{ path: "help", element: <Help /> },
		],
	},
	{
		path: "calculator",
		element: <Calculator />,
	},
	{ path: "*", element: <EmptySite /> },
]);

const contractorRouter = createBrowserRouter([
    ...baseRoutes,
	{
		path: "/",
		element: <ContractorRoot />,
		id: "contractor_root",
		loader: ContractorLoader,
		children: [
			{ path: "proposal", element: <ContractorProposal /> },
			{
				path: "dashboard",
				id: "dashboard",
				element: <ContractorDashboard />,
				loader: DashboardLoader,
				children: [
					{
						id: "dashboard_description",
						path: "description",
						element: <ContractorRoleDescription />,
					},
					{
						id: "dashboard_contract",
						path: "contract",
						element: <ContractorContractDetail />,
						loader: ContractorContractDetailLoader,
					},
					{
						id: "dashboard_new_invoice",
						path: "invoice/new",
						element: <ContractorNewInvoice />,
					},
					{
						id: "dashboard_invoice_list",
						path: "invoices",
						element: <ContractorInvoicesList />,
					},
					{
						id: "dashboard_invoice_detail",
						path: "invoices/:invoice_id",
						element: <ContractorDetailInvoice />,
					},
				],
			},
			{
				path: "account",
				element: <ContractorAccount />,
				loader: ContractorAccountLoader,
				children: [
					{
						path: "withdraw",
						id: "withdraw",
						element: <ContractorWithdrawMethods />,
						loader: ContractorWithdrawMethodsLoader,
						children: [
							{
								path: "new",
								element: <ContractorNewWithdrawMethod />,
							},
						],
					},
					{
						path: "email-update/confirm/:userid/:uuid",
						element: <ChangeEmail />,
						loader: ChangeEmailLoader,
					},
				],
			},
			{
				path: "docs",
				element: <ClientDocs />,
				loader: ClientDocsLoader,
			},
			{ path: "help", element: <Help /> },
			{
				path: "interview",
				element: <ContractorInterviewConfirmation />,
			},
			{
				path: "reviewchanges",
				element: <ContractorReviewChanges />,
			},
		],
	},
	{
		path: "calculator",
		element: <Calculator />,
	},
	{ path: "*", element: <EmptySite /> },
]);

interface User {
	email_verified: boolean;
	first_name: string;
	last_name: string;
	phone_number: string;
	phone_verified: boolean;
	profile_type: string;
	username: string;
	organization: boolean;
}

export default function App() {
	const [token, setToken] = useState(LocalStorageManager.getToken());
	const [user, setUser] = useState<User | null>();
	const [loading, setLoading] = useState<boolean>(true);
	const [notifications, setNotifications] = useState<any[]>([]);

	const [busy, setBusy] = useState(false);

	useEffect(() => {
		setLoading(true);
		if (token) {
			LocalStorageManager.setToken(token);
			LoadData();

			//setup the interval to update the notifications
			const interval = setInterval(() => {
				updateNotifications();
			}, 10000);
			return () => clearInterval(interval);
		} else {
			setUser(null);
			setLoading(false);
		}
	}, [token]);

	const LoadData = async () => {
		try {
			await getUserData();
			await updateNotifications();
			setLoading(false);
		} catch (error) {
			setToken("");
		}
	};

	async function getUserData() {
		const userData = await ComunaAPI.getUserData(token);
		setUser(userData);
	}

	async function updateNotifications() {
		const notifications_ = await ComunaAPI.getNotifications(token);

		//reverse the array
		//notifications_.reverse();

		setNotifications(notifications_);
	}

	async function logOut() {
		setToken("");
		setUser(null);
		LocalStorageManager.setToken("");
	}

	//by default we are using the auth router for non authenticated users.
	let currentRouter = authRouter;

	//if we have a valid token and it is a client

	if (token !== "" && user?.profile_type === "client") {
		currentRouter = clientRouter;
	}

	//if we have a valid token and it is a contractor
	else if (token !== "" && user?.profile_type === "contractor") {
		currentRouter = contractorRouter;
	}

	if (loading) {
		return <div></div>;
	}

	return (
		<UserContext.Provider
			value={{
				user,
				setUser,
				token,
				setToken,
				logOut,
				notifications,
				updateNotifications,
				busy,
				setBusy
			}}>
			<RouterProvider router={currentRouter} />

			{busy && <MouseLoaderIndicator></MouseLoaderIndicator>}
			{/* <ContractorOnboardingForm /> */}
			{/* <ContractorAccountCreated nextStep={() => {}} prevStep={() => {}} /> */}
		</UserContext.Provider>
	);
}
