import { useContext, useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import { useLoaderData } from "react-router-dom";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";
import { Loader } from "../components/loader";
import {
	formatDateFromString,
	formatStringDateFromServer,
} from "../utils/formaters";
import { UserContext } from "../Providers/UserContext";

export async function loader({ params }: any) {
	var token = await LocalStorageManager.getToken();

	//first we try to get existing contracts.
	var contracts = await ComunaAPI.GetUserContracts(token);
	var orgProfile = await ComunaAPI.GetOrganizationProfile(token);

	if (contracts.length > 0) {
		//if we have one we now what to do:
		const contract_signed = true;
		const contract = contracts[0];
		return { contract_signed, contract, orgProfile };
	}

	//if no contract has been signed then we get a preview contract.
	else {
		const contract_signed = false;
		const contract = await ComunaAPI.GetContractPreview(token);
		return { contract_signed, contract, orgProfile };
	}
}

function PrintContract(){
	const divToPrint =  document.getElementById("contract_text");
	if(divToPrint!==null){
		 var divContents = divToPrint.innerHTML; 
		 var a = window.open('', '', 'height=500, width=500'); 
		 a!.document.write('<html>'); 
		 a!.document.write('<body >'); 
		 a!.document.write(divContents); 
		 a!.document.write('</body></html>'); 
		 a!.document.close(); 
		 a!.print(); 
	}
}

export function ClientDocs() {
	const { user, setBusy }: any = useContext(UserContext);
	const { contract_signed, contract, orgProfile }: any = useLoaderData();

	const [signature, setSignature] = useState("");
	const [printName, setPrintName] = useState("");

	const [signaturesMatch, setSignaturesMatch] = useState(true);
	const [signaturesMissing, setSignaturesMissing] = useState(false);
	const [showConfirmationPopup, setShowConfirmPopup] = useState(false);
	const [submitingForm, setSubmittingForm] = useState(false);
	const questionMark = require("../img/graphics/questionMark.png");

	const [incompleteInfo, setIncompleteInfo] = useState(false);

	const validateSignature = () => {
		setSignaturesMatch(true);
		setSignaturesMissing(false);

		if (signature === "") {
			setSignaturesMissing(true);
		}

		if (signature !== printName) {
			setSignaturesMatch(false);
		}

		if (signature === printName && signature !== "") {
			setShowConfirmPopup(true);
		}
	};

	const SubmitForm = async () => {
		setSubmittingForm(true);
		const data = {
			proposal: 0,
			contract: contract.contract_id,
			signature: signature,
			name: printName,
		};
		var token = await LocalStorageManager.getToken();
		await ComunaAPI.SignContract(token, data);
		window.location.reload();
	};

	useEffect(() => {
		if (user.role !== "admin") {
			window.location.href = "/hiring";
		}
		if (orgProfile !== null) {
			if (orgProfile !== undefined) {
				if (orgProfile.address_line_1 === null) {
					setIncompleteInfo(true);
				} else {
					setIncompleteInfo(false);
				}
			} else {
				console.log("org profile undefined");
			}
			console.log("org Profile: ", orgProfile);
		}

		setBusy(false);
	}, [orgProfile]);

	console.log("Contract", contract);

	return (
		<>
			<div className="flex flex-row w-full h-full">
				{/** Side Bar */}
				<div
					className="flex flex-col bg-white pt-8 px-8"
					style={{ width: 380 }}>
					{/* <b className="w-full text-3xl font-extrabold comuna-purple mb-8">
						My Docs
					</b> */}

					<div className="flex flex-row w-full h-fit items-center px-4 py-3 rounded-lg bg-comuna-light  mt-4 cursor-pointer">
						<b className="w-full left-0 text-lg font-bold color-black px-2 cursor-pointer">
							{contract.contract_name}
						</b>
						{contract_signed ? (
							<label className="bg-light-green green-text text-white p-2 rounded-lg">
								Signed
							</label>
						) : (
							<label className="bg-orange-100 comuna-orange p-2 rounded-lg">
								Pending
							</label>
						)}
					</div>
				</div>

				{/** Detail View */}
				{incompleteInfo ? (
					<div
						className="flex flex-col w-full h-full p-5 bg-comuna-purple-light-alpha"
						style={{ overflowY: "auto" }}>
						<div>
							<div
								className="bg-white rounded-xl m-5 pt-5 pb-10 px-40 text-center"
								style={{ margin: "auto", maxWidth: 1080 }}>
								<img
									src={questionMark}
									style={{ width: 55, margin: "auto", marginBottom: 20 }}
									alt=""
								/>
								<h3 className="text-2xl font-extrabold color-black">
									Information missing to generate {contract.contract_name}
								</h3>
								<p className="font-sm mt-5 px-20">
									We can’t generate your MSA because we need some more
									information of your company. Please, fill it up and come back
									here to continue.{" "}
								</p>
								<div className="w-full flex flex-col items-center mt-7">
									<button
										onClick={() => {
											window.location.href = "/account";
										}}
										className="comuna-primary-button sm">
										Fill missing information
									</button>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div
						className="flex flex-col w-full h-full p-5 bg-comuna-purple-light-alpha"
						style={{ overflowY: "auto" }}>
						<div>
							<div id="contract_text"
								className="bg-white rounded-xl m-5 p-5 pb-10"
								style={{ margin: "auto", maxWidth: 1080 }}>
								<div className="bg-comuna-purple-light-alpha rounded-xl p-6 mb-8">
									<h3 className="text-2xl font-extrabold color-black">
										{contract.contract_name}
									</h3>
									<p className="font-medium">
										This is the contract between your organization and MUNA
									</p>
								</div>

								<div 
									className="ml-3 mt-2 pr-6 comuna-contract"
									dangerouslySetInnerHTML={{ __html: contract.contract }}></div>

								<div className="px-4">
									{signaturesMissing && (
										<p className="text-red-500 ml-2 mt-3">
											* Missing your signature
										</p>
									)}
									{!signaturesMatch && (
										<p className="text-red-500 ml-2 mt-3">
											* Signatures and name do not match
										</p>
									)}

									{!contract_signed && (
										<div>
											<label className="comuna-input-label">
												Signature of beneficial owner
											</label>
											<input
												placeholder="Type your full name here"
												onChange={(e) => {
													setSignature(e.target.value);
												}}
												className="comuna-input italic font-bold"></input>

											<label className="comuna-input-label">
												Print name of beneficial owner
											</label>
											<input
												placeholder="Type your full name here"
												onChange={(e) => {
													setPrintName(e.target.value);
												}}
												className="comuna-input"></input>

											<button
												onClick={() => {
													validateSignature();
												}}
												className="comuna-primary-button mt-8 sm">
												Review Details and Sign the Contract
											</button>
										</div>
									)}

									{contract_signed && (
										<div className="comuna-disabled-button mt-8 color-gray-500 sm">
											{" "}
											Contract signed on{" "}
											<b className="ml-2">
												{formatStringDateFromServer(contract.date_signed)}
											</b>{" "}
										</div>
									)}
									
								</div>
							</div>
							<button onClick={PrintContract} className="comuna-secondary-button mt-8 sm mb-8 ml-16">Download Contract</button>
						</div>
					</div>
				)}

				<Modal show={showConfirmationPopup} onClose={() => {}}>
					<div className="flex flex-col w-full h-fit p-6 mb-12">
						<b className="text-2xl font-bold mb-4 text-center">
							Confirm your details before signing the contract
						</b>

						<label className="comuna-input-label">
							Signature of beneficial owner
						</label>
						<label className="comuna-input italic font-bold">{signature}</label>
						<label className="comuna-input-label">
							Print name of beneficial owner
						</label>
						<label className="comuna-input">{printName}</label>

						<div className="flex flex-row w-full h-fit mt-8">
							<button
								onClick={() => {
									if (!submitingForm) {
										setShowConfirmPopup(false);
									}
								}}
								className="comuna-secondary-button mr-4">
								Edit Details
							</button>
							<button onClick={SubmitForm} className="comuna-primary-button">
								{submitingForm ? <Loader /> : "Confirm and Sign Contract"}
							</button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	);
}
