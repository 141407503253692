import axios from "axios";
import { BASE_URL } from "../config";

const getCookie = (name) => {
	let cookieValue = null;
	if (document.cookie && document.cookie !== "") {
		const cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			// Does this cookie string begin with the name we want?
			if (cookie.substring(0, name.length + 1) === name + "=") {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
		return cookieValue;
	}
};

const defaultHeaders = {
	"Content-Type": "application/json",
	"X-CSRFToken": getCookie("csrftoken"),
};

const authenticatedheaders = (token, isFormData = false) => {
	if (isFormData) {
		return {
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
	}
	return {
		"Content-Type": "application/json",
		Authorization: `Token ${token}`,
		"X-CSRFToken": getCookie("csrftoken"),
	};
};

const defaultConfig = {
	headers: defaultHeaders,
};

export class ComunaAPI {
	static async signIn(email, password) {
		const signInInfo = {
			username: email.toLowerCase(),
			password: password,
		};

		const completeUrl = `${BASE_URL}signin`;
		const response = await axios.post(completeUrl, signInInfo, defaultConfig);
		console.log(response.data);
		return response.data;
	}

	static async signUp(email, password, password2) {
		//make sure email field is minus caps
		const signUpInfo = {
			email: email.toLowerCase(),
			password: password,
			password2: password2,
		};
		const completeUrl = `${BASE_URL}signup/client`;
		const response = await axios.post(completeUrl, signUpInfo, defaultConfig);
		return response.data;
	}

	static async signUpContractor(contractorInfo) {
		const completeUrl = `${BASE_URL}signup/contractor`;
		const response = await axios.post(
			completeUrl,
			contractorInfo,
			defaultConfig
		);
		return response.data;
	}

	static async getUserData(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};
		const completeUrl = `${BASE_URL}user`;
		const response = await axios.get(completeUrl, { headers: headers });
		// console.log(response.data);
		return response.data;
	}

	static async getNotifications(token) {
		const completeUrl = `${BASE_URL}notifications`;
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async clearNotification(token, id) {
		const completeUrl = `${BASE_URL}notifications/clear/${id}`;
		const response = await axios.post(
			completeUrl,
			{},
			{ headers: authenticatedheaders(token) }
		);
		return response.data;
	}

	static async step1VerifyEmail(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};
		const completeUrl = `${BASE_URL}verify/email`;
		const response = await axios.get(completeUrl, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async step2VerifyEmail(token, emailCode) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};
		const data = {
			code: emailCode,
		};
		const completeUrl = `${BASE_URL}verify/email`;
		const response = await axios.post(completeUrl, data, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async SendNewResourceRequest(token, roleInfo, benefits) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};

		//turn skills into an array of numbers if they come as objects
		var skills_ = [];
		if(roleInfo.roleSkills.length>0 && roleInfo.roleSkills[0].value){
			roleInfo.roleSkills.forEach((skill) => {
				skills_.push(skill.value);
			});
		}else{
			skills_ = roleInfo.roleSkills;
		}

		const newResReqInfo = {
			role_name: roleInfo.roleName,
			quantity: roleInfo.roleQuantity,
			modality: roleInfo.roleModality,
			seniority: roleInfo.roleSeniority,
			skills: skills_,
			position_overview: roleInfo.roleDescription,
			benefits: benefits,
			start_date: roleInfo.roleStartDate,
			location: roleInfo.roleLocation,
			additional_details: roleInfo.roleDetails,
		};

		//client email is an optional field
		//for the special case a staff member is creating a role for a client.
		if (roleInfo.client_email) {
			newResReqInfo.client_email = roleInfo.client_email;
		}

		const completeUrl = `${BASE_URL}resource_request`;

		try {
			const response = await axios.post(completeUrl, newResReqInfo, {
				headers: headers,
			});
			if (response.status === 201 || response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			return null;
		}
	}

	static async SendEditResourceRequest(token, roleInfo, benefits, rrId) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};

		//turn skills into an array of numbers
		var skills_ = [];
		roleInfo.roleSkills.forEach((skill) => {
			skills_.push(skill.value);
		});

		const newResReqInfo = {
			role_name: roleInfo.roleName,
			quantity: roleInfo.roleQuantity,
			modality: roleInfo.roleModality,
			seniority: roleInfo.roleSeniority,
			skills: skills_,
			position_overview: roleInfo.roleDescription,
			benefits: benefits,
			start_date: roleInfo.roleStartDate,
			location: roleInfo.roleLocation,
			additional_details: roleInfo.roleDetails,
		};
		const completeUrl = `${BASE_URL}resource_request/` + rrId + "/edit";

		try {
			const response = await axios.post(completeUrl, newResReqInfo, {
				headers: headers,
			});
			if (response.status === 201 || response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			return null;
		}
	}

	static async softDeleteResourceRequest(token, rrId) {
		try {
			const headers = {
				"Content-Type": "application/json",
				Authorization: `Token ${token}`,
			};
			const completeUrl = `${BASE_URL}resource_request/` + rrId + "/delete";
			const data = {};
			const response = await axios.post(completeUrl, data, {
				headers: headers,
			});
			return response.data;
		} catch (error) {
			console.log("ERROR TRYING TO DELETE", error);
			return false;
		}
	}

	static async getUserResourceRequests(token) {
		try {
			const headers = {
				"Content-Type": "application/json",
				Authorization: `Token ${token}`,
				"X-CSRFToken": getCookie("csrftoken"),
			};
			const completeUrl = `${BASE_URL}resource_request`;
			const response = await axios.get(completeUrl, { headers: headers });
			return response.data;
		} catch (error) {
			console.warn("Error getting resource requests", error);
			return [];
		}
	}

	static async getResReqFields(token, organizationId = null) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
	
		const completeUrl = `${BASE_URL}resource_request/fields`;
	
		// Prepare query parameters
		const params = {};
		if (organizationId) {
			params.organization_id = organizationId;
		}
	
		try {
			const response = await axios.get(completeUrl, {
				headers: headers,
				params: params,
			});
			return response.data;
		} catch (error) {
			// Handle errors appropriately
			console.error("Error fetching resource request fields:", error);
			throw error;
		}
	}	

	static async getResourceRequestInfoById(token, id) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};

		const completeUrl = `${BASE_URL}resource_request/${id}`;
		const response = await axios.get(completeUrl, { headers: headers });
		return response.data;
	}

	static async GetOrganizationProfile(token) {
		const completeUrl = `${BASE_URL}organization_profile`;
		try {
			const response = await axios.get(completeUrl, {
				headers: authenticatedheaders(token),
			});
			if (response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			return null;
		}
	}

	static async CreateOrganization(token, newOrgInfo) {
		const completeUrl = `${BASE_URL}organization_profile`;
		const formData = new FormData();

		for (const key in newOrgInfo) {
			if (key !== "profile_picture") {
				formData.append(key, newOrgInfo[key]);
			}
		}

		if (newOrgInfo.profile_picture) {
			//add a timestamp to the name of the file to prevent overriding existing ones
			const timestamp = new Date().getTime();
			const fileName =
				newOrgInfo.name.replace(/ /g, "_") +
				timestamp +
				"." +
				getImageFormat(newOrgInfo.profile_picture.name);
			formData.append("profile_picture", newOrgInfo.profile_picture, fileName);
		}

		const response = await axios.post(completeUrl, formData, {
			headers: authenticatedheaders(token, true),
		});

		if (response.status === 201 || response.status === 200) {
			return true;
		}

		return false;
	}

	static async GetOrganizationRoles(token) {
		const completeUrl = `${BASE_URL}organization/roles`;
		try {
			const response = await axios.get(completeUrl, {
				headers: authenticatedheaders(token),
			});
			if (response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			return null;
		}
	}

	static async CreateNewOrganizationRole(token, newRoleInfo) {
		const completeUrl = `${BASE_URL}organization/roles`;
		try {
			const response = await axios.post(completeUrl, newRoleInfo, {
				headers: authenticatedheaders(token),
			});
			if (response.status === 201) {
				return response.data;
			} else {
				console.log("Error creating role: ", response.data);
				return null;
			}
		} catch (error) {
			return null;
		}
	}

	static async EditOrganizationRole(token, editRoleInfo) {
		const completeUrl = `${BASE_URL}organization/roles`;
		try {
			const response = await axios.put(completeUrl, editRoleInfo, {
				headers: authenticatedheaders(token),
			});
			if (response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			return null;
		}
	}

	static async DeleteOrganizationRole(token, deleteRoleInfo) {
		const completeUrl = `${BASE_URL}organization/roles`;
		try {
			const response = await axios.delete(completeUrl, {
				headers: authenticatedheaders(token),
				data: deleteRoleInfo,
			});
			if (response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			return null;
		}
	}

	static async getProposalFields(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}proposal/fields`;
		const response = await axios.get(completeUrl, { headers: headers });
		return response.data;
	}

	static async GetUserProposals(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}proposal`;
		const response = await axios.get(completeUrl, { headers: headers });
		return response.data;
	}

	static async GetUserContracts(token) {
		const completeUrl = `${BASE_URL}contracts`;
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async ApproveContractChanges(token, id) {
		try {
			const completeUrl = `${BASE_URL}contract-changes/approve/${id}`;
			const response = await axios.post(
				completeUrl,
				{},
				{
					headers: authenticatedheaders(token),
				}
			);
			return response.data;
		} catch (error) {
			console.log(error);
			return false;
		}
	}

	static async GetContractPreview(token) {
		const completeUrl = `${BASE_URL}contract/preview`;
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async GetContractPreviewWithProposalId(token, proposalId) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}contract/preview/${proposalId}`;
		const response = await axios.get(completeUrl, { headers: headers });
		return response.data;
	}

	static async SignContract(token, data) {
		const completeUrl = `${BASE_URL}contract/sign`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async GetInvoices(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}invoice`;
		// try {
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		// if (response.status === 200) {
		return response.data;
		// } else {
		// 	return null;
		// }
		// } catch (error) {
		// 	return null;
		// }
	}

	static async CreateInvoice(token, data) {
		try {
			const completeUrl = `${BASE_URL}invoice/`;
			const response = await axios.post(completeUrl, data, {
				headers: authenticatedheaders(token),
			});
			return response.data;
		} catch (error) {
			console.log("Error on creating invoice: ", error);
			return false;
		}
	}

	static async PayInvoice(token, data) {
		const completeUrl = `${BASE_URL}invoice/`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async ChallengeInvoice(token, data) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}invoice/`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async CreateProposal(token, proposal) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}proposal`;
		const response = await axios.post(completeUrl, proposal, {
			headers: headers,
		});
		return response.data;
	}

	static async ModifyProposal(token, id, proposal) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}proposal/${id}/edit`;
		const response = await axios.post(completeUrl, proposal, {
			headers: headers,
		});
		return response.data;
	}

	static async UpdateProposal(token, id, proposalUpdate) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}proposal/${id}`;
		const response = await axios.post(completeUrl, proposalUpdate, {
			headers: headers,
		});
		return response.data;
	}

	static async UpdateApplication(token, id, updateInfo) {
		console.log("updateInfo", updateInfo, "id", id, "token", token);
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}application/${id}`;
		const response = await axios.post(completeUrl, updateInfo, {
			headers: headers,
		});
		return response.data;
	}

	static async AskForInterviewApplication(token, id, data) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}application/${id}/interview`;
		const response = await axios.post(completeUrl, data, {
			headers: headers,
		});
		return response.data;
	}

	static async GetInterview(token, id) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
	
		const completeUrl = `${BASE_URL}interview/${id}`;
		try {
			const response = await axios.get(completeUrl, { headers: headers });
			if (response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			console.error("Error fetching contractor interviews", error);
			return null;
		}
	}	

	static async MakeInterviewViewed(token, id){
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};

		const completeUrl = `${BASE_URL}interview/${id}/mark_viewed`;
		const response = await axios.post(completeUrl, {}, { headers: headers });
		return response.data;
	}

	static async GetApplication(token, id) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}application/${id}`;
		const response = await axios.get(completeUrl, { headers: headers });
		return response.data;
	}

	static async GetPaymentMethods(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}withdraw_methods`;
		const response = await axios.get(completeUrl, { headers: headers });
		return response.data;
	}

	static async GetMyTeam(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}myteam/`;
		const response = await axios.get(completeUrl, { headers: headers });
		return response.data;
	}

	static async SendHelpMessage(token, helpInfo) {
		const completeUrl = `${BASE_URL}help_ticket/`;
		const response = await axios.post(completeUrl, helpInfo, {
			headers: authenticatedheaders(token),
		});
		if (response.status === 201 || response.status === 200) {
			return true;
		}
		return false;
	}

	static async UpdateContractorContract(token, id, data) {
		const completeUrl = `${BASE_URL}contract/edit/` + id;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async getUpdatesForContractorContracts(token) {
		const completeUrl = `${BASE_URL}contract/edit/`;
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async setNewFinishDateForContractorContract(token, data) {
		const completeUrl = `${BASE_URL}contract/end/`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async createOneTimePaymentForcontractorContract(token, id, data) {
		const completeUrl = `${BASE_URL}one_time_payment/` + id;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async cancelOneTimePaymentForcontractorContract(token, id) {
		const completeUrl = `${BASE_URL}one_time_payment/delete/` + id;
		const response = await axios.delete(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async UpdateAccountDetails(token, data) {
		const completeUrl = `${BASE_URL}account/`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async UpdateOrganizationDetails(token, orgData, images, facts) {
		const completeUrl = `${BASE_URL}organization_profile`;

		//turn data into a form data object
		const formData = new FormData();

		// Append organization data
		for (const key in orgData) {
			let value = orgData[key];
			
			// Convert booleans to their correct string representation if needed
			if (typeof value === "boolean") {
				value = value ? "True" : "False";
			}
			
			formData.append(key, value);
		}

		const imgsToDelete = [];

		//add the images
		if (images && images.length > 0) {
			images.forEach((image, index) => {
				if (image.new) {
					//add a timestamp to the name of the file to prevent overriding existing ones
					const timestamp = new Date().getTime();
					const fileName =
						orgData.name.replace(/ /g, "_") +
						timestamp +
						"." +
						getImageFormat(image.file.name);
					formData.append("organization_image_" + index, image.file, fileName);
				}
				if (image.delete) {
					//mark the image for deletion
					imgsToDelete.push(image.image.split("/").pop());
				}
			});
		}

		//add the facts
		if (facts && facts.length > 0) {
			formData.append(
				"facts",
				JSON.stringify({
					facts: facts,
				})
			);
		}

		//make the request
		const response = await axios.post(completeUrl, formData, {
			headers: authenticatedheaders(token, true),
		});

		//delete the images
		if (imgsToDelete.length > 0) {
			const data_ = {
				image_names: imgsToDelete,
			};
			const url_ = `${BASE_URL}organization_images/delete`;
			await axios.post(url_, data_, {
				headers: authenticatedheaders(token),
			});
		}

		return response.data;
	}

	static async GetWithdrawalMethods(token) {
		const completeUrl = `${BASE_URL}withdraw_methods/`;
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async AddWithdrawalMethods(token, data) {
		const completeUrl = `${BASE_URL}withdraw_methods/`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async getInterviewDates(uuid) {
		const headers = {
			"Content-Type": "application/json",
		};

		const completeUrl = `${BASE_URL}application/interview/answer?uuid=${uuid}`;
		const response = await axios.get(completeUrl, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async answerInterview(uuid, action, acceptedDate) {
		const headers = {
			"Content-Type": "application/json",
		};

		const completeUrl = `${BASE_URL}application/interview/answer`;

		const data = {
			uuid: uuid,
			update: action,
			accepted_date: acceptedDate,
		};

		const response = await axios.post(completeUrl, data, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async triggerForgotPassword(email) {
		const headers = {
			"Content-Type": "application/json",
		};

		const completeUrl = `${BASE_URL}user/forgot_password`;

		const data = {
			email: email,
		};
		console.log("complete url is: ", completeUrl);
		console.log("payload is: ", data);

		const response = await axios.post(completeUrl, data, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async forgotPasswordConfirm(userId, emailToken, newPassword) {
		try {
			const headers = {
				"Content-Type": "application/json",
			};

			const completeUrl = `${BASE_URL}user/reset_password/${userId}/${emailToken}/`;

			const data = {
				password: newPassword,
			};

			const response = await axios.post(completeUrl, data, {
				headers: headers,
			});
			if (response.status === 200) {
				return true;
			}
		} catch (error) {
			return false;
		}
		return false;
	}

	static async startEmailUpdate(token, data) {
		const completeUrl = `${BASE_URL}email-update/`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async emailUpdate(token, data, userid, uuid) {
		console.log("data is: ", data);
		console.log("userid is: ", userid);
		console.log("uuid is: ", uuid);
		const completeUrl = `${BASE_URL}email-update/confirm/${userid}/${uuid}/`;
		console.log("complete url is: ", completeUrl);
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async previewInvoiceContractor(token) {
		const completeUrl = `${BASE_URL}invoice/preview`;
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async getCalculatorFields() {
		const completeUrl = `${BASE_URL}salaryrecommendation/fields`;
		const response = await axios.get(completeUrl);
		return response.data;
	}

	static async getCalculatorData(role, seniority, comp, tech) {
		const completeUrl = `${BASE_URL}salaryrecommendation?role=${role}&seniority=${seniority}&comp=${comp}&skill=${tech}`;
		const response = await axios.get(completeUrl);
		return response.data;
	}

	static async GetOrganizationList(token) {
		const completeUrl = `${BASE_URL}staff/organizations`;
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async SwitchOrganization(token, orgId) {
		const completeUrl = `${BASE_URL}staff/organizations`;
		const data = {
			organization_id: orgId,
		};
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async GetQuestionCategories(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
	
		const completeUrl = `${BASE_URL}question_categories`;
		try {
			const response = await axios.get(completeUrl, { headers: headers });
			if (response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			console.error("Error fetching question categories", error);
			return null;
		}
	}

	static async ShareResourceRequest(token, id, shared) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const data = {
			shared: shared
		};
		const completeUrl = `${BASE_URL}resource_request/${id}/share`;
		try {
			const response = await axios.post(completeUrl, data, { headers: headers });
			return response.data;
		} catch (error) {
			console.error("Error sharing resource request", error);
			return null;
		}
	}

	static async GetSharedResourceRequest(id, key){
		const completeUrl = `${BASE_URL}resource_request/shared/${id}/${key}`;
		try {
			const response = await axios.get(completeUrl);
			return response.data;
		} catch (error) {
			console.error("Error sharing resource request", error);
			return null;
		}

	}
}

function getImageFormat(filename) {
	// Use regular expression to extract the image format
	const match = filename.match(/\.(.+)$/);

	// Check if a match is found
	if (match && match[1]) {
		// Convert the image format to lowercase for consistency
		return match[1].toLowerCase();
	} else {
		// Return null if no match is found
		return null;
	}
}
