
import { useEffect, useState } from "react"
import { ComunaAPI } from "../managers/ComunaAPI";
import Select from "react-select";

const logoBlack = require('../img/logo_black.png')

const hand1 = require('../img/graphics/hand_1.png')
const hand2 = require('../img/graphics/hand_2.png')
const hand3 = require('../img/graphics/hand_3.png')
const arrow = require('../img/graphics/arrow.png')


function formatNumber(num:string|number){
    const n = Number(num);
    return "$ "+(n/1000).toFixed(0)+ "K"
}

function Bar(props:any){

    const total = props.max//props.salary+props.fees;
    const salary_width = (props.salary/total)*100;
    const fees_width = (props.fees/total)*100;

    return(
        <div className="ml-4 mr-2 comuna-bg w-full rounded-xl flex flex-row" style={{ overflow:"hidden", width:"97%"}}>
            <div className="h-full bg-comuna-purple flex flex-col items-end py-2 px-4" style={{width:salary_width+"%", minWidth:153}}>
                <span className="text-white font-bold text-2xl whitespace-nowrap">{formatNumber(props.salary)}</span>
                <span className="text-white text-xs whitespace-nowrap">{props.firstBarText}</span>
            </div>
            {props.fees > 0 &&
            <div className="h-full bg-comuna-purple-light  flex flex-col items-end py-2 px-4 rounded-r-xl" style={{width:fees_width+"%", minWidth:props.comuna?90:190}}>
                <span className="text-white font-bold text-2xl whitespace-nowrap">{formatNumber(props.fees)}</span>
                <span className="text-white text-xs whitespace-nowrap">{props.secondBarText}</span>
            </div>}
        </div>
    )
}


export function Calculator() {
    const seniorityOptions = [
        {value:1, label:"Junior"},
        {value:2, label:"Intermediate"},
        {value:3, label:"Senior"},
        {value:4, label:"Staff"},
    ]

    const compOptions = [
        {value:25, label:"25th"},
        {value:50, label:"50th"},
        {value:75, label:"75th"},
        {value:99, label:"99th"},
    ]

    const [calcData, setCalcData] = useState<any>({});
    
    const [currentRoleSelected, setCurrentRoleSelected] = useState<any>(null);
    const [roleOptions, setRoleOptions] = useState<any>([]);

    const [currentTechSelected, setCurrentTechSelected] = useState<any>(null);
    const [techOptions, setTechOptions] = useState<any>([]);

    const [seniority, setSeniority] = useState<any>(seniorityOptions[0]);
    const [comp, setComp] = useState<any>(compOptions[0]);

    

    useEffect(()=>{
        Init();

        function updateScale() {

            // var screenWidth = window.innerWidth;
            // var scaleFactor = screenWidth / 1024; // Adjust 800 to your preferred base width
            
            // var scaleValue = 'scale(' + scaleFactor + ')';
            // if(screenWidth>1024){
            //     scaleValue = 'scale(1)';
            // }
            
            // const e = document.getElementById('responsiveElement');
            // if(e!=null){
            //     e.style.transform = scaleValue;
            // }
            
          }
      
          // Initial update on page load
          window.addEventListener('load', updateScale);
      
          // Update scale on window resize
          window.addEventListener('resize', updateScale);

    }, []);

    useEffect(()=>{
        updateCalcData();
    }, [currentRoleSelected, seniority, comp, currentTechSelected])

    async function Init(){

        const fieldsData = await ComunaAPI.getCalculatorFields();

        const newRoles:any[] = []
        fieldsData.role_recommendations.forEach((r:any)=>{  
            newRoles.push({value:r.role_name, label:r.role_name})
        }) 
        setRoleOptions(newRoles);
        setCurrentRoleSelected(newRoles[0]);

        const newTechs:any[] = []
        fieldsData.skills.forEach((t:any)=>{  
            newTechs.push({value:t.name, label:t.name})
        })
        setTechOptions(newTechs)
        setCurrentTechSelected(newTechs[0]);
    }

    async function updateCalcData(){
        const data = await ComunaAPI.getCalculatorData(currentRoleSelected.value, seniority.value, comp.value, currentTechSelected.value);
        if(currentRoleSelected.value=="Technical Support Specialist"){
            data.comuna_salary *=0.85;
            data.comuna_fee *=0.85;
            data.usa_salary *=0.70;
            data.usa_fee *=0.70;
            data.outsorcing_salary *=0.85;
            data.outsorcing_fee *=0.85;
        }
        else if(currentRoleSelected.value=="Customer Success Manager"){
            data.comuna_salary *=0.90;
            data.comuna_fee *=0.90;
            data.usa_salary *=0.80;
            data.usa_fee *=0.80;
            data.outsorcing_salary *=0.90;
            data.outsorcing_fee *=0.90;
        }
        setCalcData(data);
    }

    return (
    <div className="w-full bg-comuna-purple calculator-bg min-height-full pt-12 pb-12">
        <h1 className="text-5xl font-extrabold text-center mb-12" style={{color:"#D7FFB8"}}>
            Comparison calculator 
		</h1>

        <img src={hand2} alt="hand holding a magnifying glass" 
                style={{
                    position: "absolute",
                    top: 340,
                    left: -20,
                    zIndex: 10,
                    minWidth: 90,
                    maxWidth: 240,
                    width: "15%"
                }}/>

        <img src={hand1} alt="hand holding a bill" style={{
                    position: "absolute",
                    top: 98,
                    right: 0,
                    zIndex: 10,
                    minWidth: 90,
                    maxWidth: 193,
                    width: "15%"
                }}/>

        <img src={hand3}
                alt="hand holding a bill" style={{
                    position: "absolute",
                    top: 10,
                    left: 120,
                    zIndex: 0,
                    minWidth: 180,
                    maxWidth: 300,
                    width: "15%"
        }}/>

        <div className="flex flex-col mx-auto px-2 my-20" style={{width:"90%"}}>
            <div className="flex md:flex-row p-4 flex-col bg-white rounded-xl relative">

               
                <div className="flex flex-col w-full mx-2">
                    <label className="comuna-input-label">Role</label>
                    <Select
                    options={roleOptions}
                    value={currentRoleSelected}
                    onChange={(s) => {
                        setCurrentRoleSelected(s);
                    }}
                    className="comuna-select on-front"
                    classNames={{
                        menuList: (state) => "bg-white",
                    }} />
                </div>

                <div className="flex flex-col w-full mx-2">
                    <label className="comuna-input-label">Seniority</label>
                    <Select
                    options={seniorityOptions}
                    value={seniority}
                    onChange={(s) => {
                        setSeniority(s);
                    }}
                    className="comuna-select on-front"
                    classNames={{
                        menuList: (state) => "bg-white",
                    }} />
                </div>

                <div className="flex flex-col  w-full mx-2">
                    <label className="comuna-input-label">Comp. percentile</label>
                    <Select
                    options={compOptions}
                    value={comp}
                    onChange={(s) => {
                        setComp(s);
                    }}
                    className="comuna-select on-front"
                    classNames={{
                        menuList: (state) => "bg-white",
                    }} />
                </div>

                <div className="flex flex-col w-full mx-2">
                    <label className="comuna-input-label">Technology</label>
                    <Select
                    options={techOptions}
                    value={currentTechSelected}
                    onChange={(s) => {
                        setCurrentTechSelected(s);
                    }}
                    className="comuna-select on-front"
                    classNames={{
                        menuList: (state) => "bg-white",
                    }} />
                </div>

            </div>

            <div id="responsiveElement" className="bg-white rounded-xl flex flex-col p-4 mt-8 pt-12 relative" style={{transformOrigin:"0 0"}}>

                <img src={arrow} alt="purple arrow" style={{
                    position: "absolute",
                    bottom: -8,
                    right: 42,
                    zIndex: 10,
                    width: 122
                }} />
                
                <div className="flex flex-row items-center">

                    <div className="flex flex-col items-start md:flex-row md:items-center w-full">
                        <h3 className="font-bold text-black w-1/4 text-left md:text-right text-xl pl-4 mb-2">USA</h3>
                        <Bar 
                        salary={calcData.usa_salary} 
                        fees={calcData.usa_fee} 
                        firstBarText="Salary"
                        secondBarText="Benefits + Taxes"
                        max={calcData.usa_salary+calcData.usa_fee}/>
                    </div>

                    <span className="text-2xl w-32 bg-gray-1 font-bold text-center p-4 rounded-xl whitespace-nowrap mx-2 relative hidden md:block">
                        {formatNumber(calcData.usa_salary+calcData.usa_fee)}
                        <span className="absolute font-normal text-xs text-black" style={{top:-25, left: "14%"}} >Employer cost</span>
                    </span>
                    <span className="text-2xl w-32  comuna-green-bold font-bold text-center p-4 rounded-xl whitespace-nowrap mx-2 relative hidden md:block">
                        {formatNumber(calcData.usa_salary+calcData.usa_fee-calcData.comuna_salary-calcData.comuna_fee)}
                        <span className="absolute font-normal text-xs text-black" style={{top:-25, left: "7%", color:"#13AD13"}} >MUNA Savings</span>   
                    </span>
                </div>
                
                <div className="flex flex-row items-center mt-6">

                    <div className="flex flex-col items-start md:flex-row md:items-center w-full">
                        <h3 className="font-bold text-black w-1/4 text-left md:text-right text-xl pl-4 mb-2">Outsourcing</h3>
                        <Bar 
                        firstBarText="Salary + Taxes + Benefits"
                        secondBarText="Fees (profit to middlemen)"
                        salary={calcData.outsourcing_salary} 
                        fees={calcData.outsourcing_fee}  
                        max={calcData.usa_salary+calcData.usa_fee}/>
                    </div>
                    
                    <span className="text-2xl w-32  bg-gray-1 font-bold text-center p-4 rounded-xl whitespace-nowrap mx-2 hidden md:block">
                        {formatNumber(calcData.outsourcing_salary+calcData.outsourcing_fee)}
                    </span>
                    <span className="text-2xl w-32  comuna-green-bold font-bold text-center p-4 rounded-xl whitespace-nowrap mx-2 hidden md:block">
                        {formatNumber(calcData.outsourcing_salary+calcData.outsourcing_fee-calcData.comuna_salary-calcData.comuna_fee)}
                    </span>
                </div>

                <div className="flex flex-row items-center mt-6">

                    <div className="flex flex-col items-start md:flex-row md:items-center w-full">
                        <div className="w-1/4 text-xl pl-4 pb-2">
                            <img src={logoBlack} alt="MUNA" className="w-20 float-left  md:float-right "/>
                        </div>
                        <Bar 
                        firstBarText="Salary + Taxes + Benefits"
                        secondBarText="Fees"
                        salary={calcData.comuna_salary} 
                        fees={calcData.comuna_fee} 
                         max={calcData.usa_salary+calcData.usa_fee} 
                         comuna/>
                    </div>

                    <span className="text-2xl w-32 text-center p-4 rounded-xl whitespace-nowrap mx-2 comuna-purple-bold hidden md:block">
                        {formatNumber(calcData.comuna_salary+calcData.comuna_fee)}
                    </span>
                    <span className="text-2xl w-32  bg-white text-white font-bold text-center p-4 rounded-xl whitespace-nowrap mx-2 hidden md:block">$ 123k</span>
                </div>
            </div>
        </div>
    </div>)
}